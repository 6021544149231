.info-cols {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 140px;
}

.info-cols .col:first-child {
    flex: 0 0 335px;
    max-width: 335px;
}

.info-cols .image-item {
    background-color: #9cdbff;
    border-radius: 24px;
    overflow: hidden;
}

.info-cols .image-item img {
    display: block;
    width: 100%;
    margin-bottom: -1px;
}

.info-cols a {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    background-color: #f9be64;
    font-family: Titan One, sans-serif;
    color: #202125;
    margin-top: 15px;
    font-size: 18px;
    border-radius: 24px;
    border: 2px solid transparent;
    transition: all .3s linear;
}

.info-cols a img {
    display: block;
    margin-top: 5px;
}

.info-cols a > div {
    margin-top: 6px;
}

.info-cols .col:nth-child(2) {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 350px);
    max-width: calc(100% - 350px);
}

.info-cols .content {
    width: 100%;
    padding: 60px 70px;
    background-color: #ffffff;
    text-align: center;
    border-radius: 24px;
}

.info-cols .content .label {
    font-size: 26px;
    line-height: 34px;
    font-family: Titan One, sans-serif;
    margin: 15px 0;
}

.info-cols .content p {
    margin: 0;
    color: #494a4c;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
}

.info-cols a:hover {
    background-color: #fff;
    border-color: #202125;
}